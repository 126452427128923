@charset "UTF-8";

/// Converts shorthand to the 4-value syntax.
///
/// @argument {list} $shorthand
///
/// @example scss
///   .element {
///     margin: _unpack(1em 2em);
///   }
///
/// @example css
///   .element {
///     margin: 1em 2em 1em 2em;
///   }
///
/// @access private

@function _unpack($shorthand) {
  @if length($shorthand) == 1 {
    @return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
  } @else if length($shorthand) == 2 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
  } @else if length($shorthand) == 3 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
  } @else {
    @return $shorthand;
  }
}